.banner::after {
  content: url("../assets/images/banner.svg");
  position: absolute;
  top: 0px;
  right: 0px;
  z-index: 0 !important;
}
.banner {
  position: relative;
  /* z-index: -1; */
  min-height: 79vh;
}

.contact-banner {
  position: relative;
  min-height: 79vh;
  width: 100%;
}

.contact-banner::after {
  content: url("../assets/images/banner.svg");
  position: absolute;
  width: 30rem;
  height: 10vh;
  top: -80px;
  right: 0px !important;
  z-index: 0 !important;
}

.header {
  position: fixed;
  background-color: transparent;
  z-index: 2 !important;
  top: 0px;
  left: 0px;
  width: 100% !important;
  max-width: 100%;
  margin: 0px !important;
}

.is-sticky {
  background-color: #fff;
  --tw-shadow: 0 4px 6px -1px rgb(0 0 0 / 0.1), 0 2px 4px -2px rgb(0 0 0 / 0.1);
  --tw-shadow-colored: 0 4px 6px -1px var(--tw-shadow-color),
    0 2px 4px -2px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
    var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.trigger-dropdown {
  position: relative;
}

.trigger-dropdown:hover .dropdown {
  visibility: visible;
  /* top: 40px; */
}

.dropdown {
  position: absolute;
  top: 27px;
  left: -50px;
  background-color:#F5F7FB;
  width: 17.5rem;
  visibility: hidden;
  /* transition: 0.4s; */
  box-shadow: 0px 6px 36px rgba(218, 12, 12, 0.07);
  border-radius: 16px;
}
.box-shadow {
  box-shadow:"0px 0px 30px 10px rgba(105, 103, 103, 0.13)";
   background-color:"#d1cece1c";
    border-radius:15
}
/* service card styles */

.service-card {
  position: relative;
  min-height: 352px;
}

.service-detail {
  background-image: url("../assets/images/service-bg.png");
  background-size: cover;
  background-position: center;
  min-height: 135vh;
  max-width: 100%;
  position: relative;
}

.service-detail::before {
  content: url("../assets/icons/circle-icon.svg");
  position: absolute;
  bottom: 75px;
  right: 0px;
  height: 20vh;
  z-index: 0;
}

.service-card::after {
  /* content: "";
  position: absolute;
  top: -19px;
  right: -4px;
  width: 30px;
  height: 60px;
  background-color: #f5f7fb;
  transform: rotate(-45deg); */
}

.complex {
  background-image: url("../assets/images/complex.png");
  background-repeat: no-repeat;
  background-size: contain;
  height: 30vh;
}

.contact-us {
  /* background-image: url("../assets/images/contact-bg.png");
  background-repeat: no-repeat;
  background-size: cover; */
  /* min-height: 90vh; */
  background-color: #fff;
  width: 100%;
  position: relative;
  z-index: 1 !important;
}

.contact-us::before {
  content: url("../assets/icons/contactIcon.svg");
  position: absolute;
  top: 60px;
  left: -50px;
  z-index: -1;
}
.contact-us::after {
  content: "";
  width: 80px;
  height: 100px;
  background-color: #fff;
  position: absolute;
  top: 40px;
  left: 0px;
  z-index: -1;
}

.contact-blue {
  position: relative;
}

.contact-blue::before {
  content: url("../assets/images/blue-vertor.png");
  position: absolute;
  top: -230px;
  left: -40px;
  height: 20vh;
}
.cycle {
  position: relative;
  z-index: 1 !important;
}

.cycle::before {
  content: "";
  position: absolute;
  top: -30px;
  right: 20px;
  width: 300px;
  height: 300px;
  border-radius: 50%;
  background-color: #ff7575;
  z-index: -1;
}

.cycle::after {
  content: url("../assets/icons/Ellipse2.svg");
  position: absolute;
  bottom: 130px;
  right: -80px;
  border-radius: 50%;
  z-index: -1;
}

.blueBG {
  position: relative;
  left: -22px;
}
.blueBG::before {
  content: url("../assets/icons/Ellipse2.svg");
  position: absolute;
  top: -75px;
  left: 0px;
}

.career-card {
  position: relative;
}
.career-card::before {
  content: "";
  position: absolute;
  top: -18px;
  right: -5px;
  width: 30px;
  height: 60px;
  background-color: #fff;
  transform: rotate(-45deg);
}

input:focus {
  --tw-ring-shadow: none !important;
  box-shadow: none !important;
  border-color: none !important;
  border-color: #3faeff82 !important;
}

.footerDiv {
  position: relative;
}
.footerDiv::after {
  content: "";
  position: absolute;
  top: 30px;
  right: 50px;
  width: 1px;
  height: 228.64px;
  background-color: #fff;
  border: none;
}
.nav:hover {
  color: #3faeff;
}
.navActive {
  color: #3faeff;
  font-weight: 700;
}
.navActive::after {
  content: "";
  position: absolute;
  bottom: 0px;
  left: 15%;
  /* width: 60%;
  height: 2px; */
  /* margin-left: '5%'; */
  background-color: #3faeff;
}
.mainContainer {
  @apply container w-10/12 mx-auto px-0;
}
/* react slick slider */

.center .slick-center {
  transform: scale(1.2);
  z-index: 1;
}

.center .slick-slide {
  margin: 0 10px;
}
.slick-slide {
  margin: 0 !important;
  padding: 0 !important;
}
.slick-center-slide {
  outline: none;
  border: 0px solid  ;
  border-radius: 10px;
  background-color: #DAF2FF;
}
.slick-slide {
  margin: 0 10px;
}

.slick-center-slide {
  margin: 0; 
}
.slider-container {
  margin: 0 auto;
  width: 100%;
}

.slick-left {
  background-color: blue;
}

.slick-right {
  background-color: green;
}
.slide-item {
  /* background-color: #fff; */
  /* border: 1px solid #ccc; */
  text-align: left;
  /* height: 300px; */
  margin: 0px 30px;
}

.slick-slide {
  margin: 0 10px;
}

.slick-arrow {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  width: 50px;
  height: 50px;
  background-color: rgba(189, 128, 128, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  font-size: 50px;
  color: rgb(170, 42, 42);
  cursor: pointer;
  transition: all 0.3s ease;
}

.slick-arrow{
  width: 50px;
  height: 50px;
}
.slick-next {
  width: 50px;
  height: 50px;
}
@media (max-width: 1200px) {
  .banner::after {
    width: 45%;
    height: 100vh;
    top: -80px;
    right: 0px;
  }
  .service-detail {
    min-height: 100vh;
  }
}

@media (max-width: 832px) {
  .banner::after {
    width: 70%;
    height: 100vh;
    top: -80px;
    right: 50px;
  }
  .service-card {
    min-height: 372px;
  }
}

@media (max-width: 768px) {
  .complex {
    height: 20vh;
  }
  .footerDiv::after {
    top: 30px;
    right: 10px;
  }
}

@media (max-width: 392px) {
  .footerDiv::after {
    display: none;
  }
}

@media (max-width: 592px) {
  .banner::after {
    width: 70%;
    height: 100vh;
    top: -90px;
    right: 150px;
  }
}

@media (max-width: 976px) {
  .service-card {
    min-height: 322px;
  }
}

@media (min-width: 976px) {
  .complex {
    height: 37vh;
  }
  .service-card {
    min-height: 372px;
  }
}

@media (min-width: 1440px) {
  .complex {
    height: 37vh;
  }
  .service-card {
    min-height: 322px;
  }
}

@media (min-width: 1600px) {
  .blueContainer {
    width: 89%;
    margin: 0 auto;
  }
  .service-detail {
    min-height: 145vh;
  }
}

@media (max-width: 1200px) {
  .service-detail {
    min-height: 110vh;
  }
}

@media (min-width: 1755px) {
  .blueContainer {
    width: 86%;
    margin: 0 auto;
  }
}

@media (min-width: 1831px) {
  .blueContainer {
    width: 83%;
    margin: 0 auto;
  }
}
@media (min-width: 1871px) {
  .blueContainer {
    width: 81%;
    margin: 0 auto;
  }
}

@media (min-width: 1952px) {
  .blueContainer {
    width: 78%;
    margin: 0 auto;
  }
}

@media (min-width: 2010px) {
  .blueContainer {
    width: 75%;
    margin: 0 auto;
  }
}
@media (min-width: 2052px) {
  .blueContainer {
    width: 73%;
    margin: 0 auto;
  }
}
@media (min-width: 2110px) {
  .blueContainer {
    width: 71%;
    margin: 0 auto;
  }
}

@media (min-width: 2210px) {
  .blueContainer {
    width: 68%;
    margin: 0 auto;
  }
}
@media (min-width: 2310px) {
  .blueContainer {
    width: 65%;
    margin: 0 auto;
  }
}
