@tailwind base;
@tailwind components;
@tailwind utilities;

@import url("https://fonts.googleapis.com/css2?family=Sen&display=swap");

body {
  margin: 0;
  font-family: "Sen", sans-serif;
}
